import { Content, SharedSlice, asLink } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { useEffect, useState } from 'react';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { DesktopBreakpoint, ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import clsx from 'clsx';
import { CommonButton } from 'ui/components/CommonButton';
import Link from 'next/link';
import { convertToSnakeCase } from '.';
import { VerticalTabsSliceGridWithIconClickable } from 'prismic/prismicio-types';

export type VerticalTabsProps = SliceComponentProps<
  SharedSlice<'vertical_tabs', VerticalTabsSliceGridWithIconClickable>
>;

export const VerticalTabsGridWithIcon = ({ slice }: VerticalTabsProps): JSX.Element => {
  const { items, primary } = slice;
  const { heading, sub_heading, tag_text, background_color } = primary;
  const [activeTab, setActiveTab] = useState(0);
  const [width] = useWindowsSize();
  const [bg, setBg] = useState(background_color);

  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    };
  }, []);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      data-background={background_color}
      className={clsx(
        'wide-container color-changing-section',
        '[ transition-color ease-in-out duration-1500 ][ w-full ]',
        {
          '[ bg-gray-97 ]': bg === 'light',
          '[ bg-gray-0 ]': bg === 'dark',
        },
      )}
    >
      <div className="[ w-full ][ px-5 md:px-16 lg:px-36 pt-8 md:pt-10 lg:pt-24 ]">
        <div className="[ flex flex-col justify-center items-center ][ mb-28 ]">
          <h2
            className={clsx('[ text-56 text-semibold text-left leading-snug tracking-tight ][ max-w-2xl ][ mb-6 ]', {
              '[ text-gray-0 ]': bg === 'light',
              '[ text-gray-97 ]': bg !== 'light',
            })}
          >
            {heading}
          </h2>

          <h4
            className={clsx('[ text-20 text-left ][ max-w-2xl ]', {
              '[ text-gray-40 ]': bg === 'light',
              '[ text-gray-60 ]': bg !== 'light',
            })}
          >
            {sub_heading}
          </h4>
        </div>

        <div className="[ grid grid-cols-1 lg:grid-cols-438-1fr gap-8 ]">
          <div className="[ flex flex-col ][ w-full lg:w-109.5 ][ pl-4 sm:pl-6 lg:pl-8 ][ relative ]">
            <div
              className={clsx('[ w-px h-full ][ absolute left-0 ]', {
                '[ bg-gray-60/50 ]': bg === 'light',
                '[ bg-white/50 ]': bg !== 'light',
              })}
            ></div>

            <div
              className={clsx(
                '[ bg-blue-50/10 ][ rounded-lg ][ text-14 font-semibold text-left ][ flex justify-center items-center ][ w-fit h-8 ][ mb-10 px-3 py-1 ]',
                {
                  '[ text-blue-50 ]': bg === 'light',
                  '[ text-blue-60 ]': bg !== 'light',
                },
              )}
            >
              {tag_text}
            </div>

            {items.map((tab, index) => (
              <div
                key={index}
                onClick={e => {
                  if (width < DesktopBreakpoint) {
                    document.getElementById(convertToSnakeCase(tab.heading)).scrollIntoView({
                      behavior: 'smooth',
                    });
                  }
                  setActiveTab(index);
                }}
                className={clsx('[ text-white ][ w-full ][ mb-7 ][ cursor-pointer ]', {
                  '[ border-l border-solid border-blue-30 ][ -ml-4 sm:-ml-6 lg:-ml-8 pl-4 sm:pl-6 lg:pl-8 ][ z-1000 ]':
                    activeTab === index,
                })}
              >
                <div
                  className={clsx('[ text-20 font-medium ][  ][ mb-3 ]', {
                    '[ text-gray-0/50 ]': activeTab !== index && bg === 'light',
                    '[ text-gray-0 ]': activeTab === index && bg === 'light',
                    '[ text-white/50 ]': activeTab !== index && bg !== 'light',
                    '[ text-white ]': activeTab === index && bg !== 'light',
                  })}
                >
                  {tab.heading}
                </div>

                {activeTab === index && (
                  <>
                    <div
                      className={clsx('[ text-12 ][ mb-5 ]', {
                        '[ text-gray-0 ]': bg === 'light',
                      })}
                    >
                      {tab.description}
                    </div>

                    {(asLink(tab.cta_url) && tab.cta_text) && <CommonButton
                      field={tab.cta_url}
                      color={bg === 'dark' ? 'white' : 'black'}
                      appearance="outline"
                      className="[ mb-6 lg:mb-0 ]"
                    >
                      {tab.cta_text}
                    </CommonButton>}
                  </>
                )}

                <div
                  id={convertToSnakeCase(tab.heading)}
                  className={clsx('[ block lg:hidden ]', {
                    '[ !hidden ]': activeTab !== index,
                    '[ text-gray-0 ]': bg === 'light',
                    '[ text-white ]': bg !== 'light',
                  })}
                >
                  {/* Render grid here */}
                  <div
                    className="[ grid ][ w-full ] [ gap-4  ]"
                    style={{
                      gridTemplateColumns: `repeat(auto-fit, minmax(${width > 530 ? '216px' : '143px'}, 1fr))`,
                    }}
                  >
                    {(tab as any).grid?.data?.ctas?.map(cta => {
                      return (
                        <Link href={cta.link.url || '#'} key={cta.heading}>
                          <div
                            className={clsx('[ rounded-lg ][ flex flex-col justify-between ][ min-h-28 h-full ][ p-3 ]', {
                              '[ bg-gray-90 ]': bg === 'light',
                              '[ bg-gray-5 ]': bg !== 'light',
                            })}
                          >
                            <PrismicNextImage field={cta.icon} className="[ w-8 ][ mb-2 ]" loading="lazy" />

                            <div
                              className={clsx('[ text-16 font-medium ][ max-w-36 ][ self-stretch ]', {
                                '[ text-gray-0 ]': bg === 'light',
                                '[ text-white ]': bg !== 'light',
                              })}
                            >
                              {cta.heading}
                            </div>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {items.map((item, index) => (
            <div
              key={item.heading}
              className={clsx('[ hidden lg:block ]', {
                '[ !hidden ]': activeTab !== index,
              })}
            >
              {/* Render grid icons here */}
              <div
                className="[ grid ][ w-full ] [ gap-4  ]"
                style={{
                  gridTemplateColumns: `repeat(auto-fit, minmax(${width > 1200 ? '171px' : '121px'}, 1fr))`,
                }}
              >
                {(item as any).grid?.data?.ctas?.map(cta => {
                  return (
                    <Link href={cta.link.url || '#'} key={cta.heading}>
                      <div
                        className={clsx(
                          '[ rounded-lg ][ flex flex-col justify-between relative group ][ min-h-28 h-full ][ p-3 ]',
                          {
                            '[ bg-gray-90 ]': bg === 'light',
                            '[ bg-gray-5 ]': bg !== 'light',
                          },
                        )}
                      >
                        <PrismicNextImage field={cta.icon} className="[ w-8 ][ mb-2 ]" loading="lazy" />

                        <div
                          className={clsx('[ text-16 font-medium ][ max-w-36 ][ self-stretch ]', {
                            '[ text-gray-0 ]': bg === 'light',
                            '[ text-white ]': bg !== 'light',
                          })}
                        >
                          {cta.heading}
                        </div>

                        {/* Arrow Icon */}
                        <span
                          className={clsx(
                            '[ absolute top-3 right-3 ][ text-blue-500 ][ transition-all transform ][ translate-x-4 opacity-0 ]',
                            '[ group-hover:translate-x-0 group-hover:opacity-100 ]',
                          )}
                        >
                          ➔
                        </span>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
