import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { useEffect, useState } from 'react';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { DesktopBreakpoint, ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import clsx from 'clsx';
import { CommonButton } from 'ui/components/CommonButton';
import { VerticalTabsDefault } from './VerticalTabsDefault';
import { VerticalTabsGridWithIcon } from './VerticalTabsGridWithIcon';

/**
 * Props for `VerticalTabs`.
 */
export type VerticalTabsProps = SliceComponentProps<Content.VerticalTabsSlice>;

/**
 * Component for "VerticalTabs" Slices.
 */
const VerticalTabs = ({ slice, ...rest }: VerticalTabsProps): JSX.Element => {
  if(slice.variation === 'default') {
    return <VerticalTabsDefault slice={slice} {...rest} />
  }
  if(slice.variation === 'gridWithIconClickable') {
    return <VerticalTabsGridWithIcon slice={slice} {...rest} />
  }
};
export const convertToSnakeCase = str => {
  if (str && str !== null) {
    return str.replaceAll(/[\s\n]|[A-Z]/g, match =>
      match === ' ' || match === '\n' ? '_' : `_${match.toLowerCase()}`,
    );
  }
};

export default VerticalTabs;
