import { Content, SharedSlice } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';
import { useEffect, useState } from 'react';
import { PrismicNextImage } from '@prismicio/next';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { DesktopBreakpoint, ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import clsx from 'clsx';
import { CommonButton } from 'ui/components/CommonButton';
import { convertToSnakeCase } from '.';
import { VerticalTabsSliceDefault } from 'prismic/prismicio-types';

// SliceComponentProps<SharedSlice<'content_fold', ContentFoldSliceDefault>>
export type VerticalTabsProps = SliceComponentProps<SharedSlice<'vertical_tabs', VerticalTabsSliceDefault>>;

export const VerticalTabsDefault = ({ slice }: VerticalTabsProps): JSX.Element => {
    const { items, primary } = slice;
    const { heading, sub_heading, tag_text, background_color } = primary;
    const [activeTab, setActiveTab] = useState(0);
    const [width] = useWindowsSize();
    const [bg, setBg] = useState(background_color);
  
    useEffect(() => {
      window.addEventListener('backgroundColorChange', (event: any) => {
        setBg(event.detail.color);
      });
      () => {
        window.removeEventListener('backgroundColorChange', () => {});
      }
    }, [])
    
    const getContentToRender = index => {
      const socialProof: any = items[typeof index !== 'undefined' ? index : activeTab].social_proof;
  
      switch (socialProof.type) {
        case 'block_quote': {
          const { content, title, sub_title } = socialProof.data;
  
          return (
            <div
              className={clsx(
                '[ border-l border-solid ][ text-12 ][max-w-lg ][ mt-8 pl-4 ][ [&_p]:before:content-["“"] [&_p]:after:content-["”"] [&_p]:max-w-132 ]',
                {
                  '[ border-gray-30/20 ][ [&_p]:text-gray-0 ]': bg === 'light',
                  '[ border-blue-95/20 ][ [&_p]:text-gray-40 ]': bg !== 'light',
                },
              )}
            >
              <PrismicRichText field={content} applyStyles={false} />
  
              <footer
                className={clsx(
                  {
                    '[ text-gray-0 ][ [&_p]:text-gray-0 ]': bg === 'light',
                    '[ text-gray-60 ][ [&_p]:text-gray-40 ]': bg !== 'light',
                  },
                )}
              >
                <div>{title}</div>
                <div>{sub_title}</div>
              </footer>
            </div>
          );
        }
        case 'logos': {
          const { logos } = socialProof.data;
          return (
            <div className="[ flex gap-10 items-center ][ mt-8 ]">
              {logos.map(item => (
                <PrismicNextImage key={item.logo.url} field={item.logo} loading='lazy' />
              ))}
            </div>
          );
        }
        case 'case_study_v2': {
          const { title, image, uid } = socialProof.data;
          
          return (
            <div
              className={clsx(
                '[ rounded-lg ][ grid grid-cols-1 xl:grid-cols-0.5fr-1.5fr-0.5fr items-center ][ mt-8 p-4 ]',
                {
                  '[ bg-gray-90 ]': bg === 'light',
                  '[ bg-black ]': bg !== 'light',
                },
              )}
            >
              <PrismicNextImage field={image} width={88} height={88} className="[ hidden xl:block ]" loading='lazy' />
  
              <div>
                <div
                  className={clsx(
                    '[ rounded-lg ][ text-14 font-semibold ][ flex justify-center items-center ][ w-fit h-8 ][ mb-1 px-3 py-1 ]',
                    {
                      '[ bg-gray-97 ][ text-gray-30 ]': bg === 'light',
                      '[ bg-blue-50/10 ][ text-blue-60 ]': bg !== 'light',
                    },
                  )}
                >
                  CASE STUDY
                </div>
  
                <p
                  className={clsx(
                    '[ mb-1 xl:mb-0 ]',
                    {
                      '[ text-gray-30 ]': bg === 'light',
                      '[ text-gray-40 ]': bg !== 'light',
                    },
                  )}
                >
                  {title}
                </p>
              </div>
  
              <CommonButton
                href={`/case-study/${uid}`}
                color={bg === 'dark' ? 'white' : 'black'}
                appearance="outline"
                className="[ self-end justify-self-end ]"
              >
                Read
              </CommonButton>
            </div>
          );
        }
        default:
          return <></>;
      }
    };
  
    return (
      <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        data-background={background_color}
        className={clsx(
          'wide-container color-changing-section',
          '[ transition-color ease-in-out duration-1500 ][ w-full ]',
          {
            '[ bg-gray-97 ]': bg === 'light',
            '[ bg-gray-0 ]': bg === 'dark',
          },
        )}
      >
        <div className="[ w-full ][ px-5 md:px-16 lg:px-36 pt-8 md:pt-10 lg:pt-24 ]">
          <div className="[ flex flex-col justify-center items-center ][ mb-28 ]">
            <h2
              className={clsx(
                '[ text-56 text-semibold text-left leading-snug tracking-tight ][ max-w-2xl ][ mb-6 ]',
                {
                  '[ text-gray-0 ]': bg === 'light',
                  '[ text-gray-97 ]': bg !== 'light',
                },
              )}
            >
              {heading}
            </h2>
  
            <h4
              className={clsx(
                '[ text-20 text-left ][ max-w-2xl ]',
                {
                  '[ text-gray-40 ]': bg === 'light',
                  '[ text-gray-60 ]': bg !== 'light',
                },
              )}
            >
              {sub_heading}
            </h4>
          </div>
  
          <div className="[ grid grid-cols-1 lg:grid-cols-438-1fr gap-8 ]">
            <div className="[ flex flex-col ][ w-full lg:w-109.5 ][ pl-4 sm:pl-6 lg:pl-8 ][ relative ]">
            <div
                className={clsx(
                  '[ w-px h-full ][ absolute left-0 ]',
                  {
                    '[ bg-gray-60/50 ]': bg === 'light',
                    '[ bg-white/50 ]': bg !== 'light',
                  },
                )}
              ></div>
  
              <div
                className={clsx(
                  '[ bg-blue-50/10 ][ rounded-lg ][ text-14 font-semibold text-left ][ flex justify-center items-center ][ w-fit h-8 ][ mb-10 px-3 py-1 ]',
                  {
                    '[ text-blue-50 ]': bg === 'light',
                    '[ text-blue-60 ]': bg !== 'light',
                  },
                )}
              >
                {tag_text}
              </div>
  
              {items.map((tab, index) => (
                <div
                  key={index}
                  onClick={e => {
                    if (width < DesktopBreakpoint) {
                      document.getElementById(convertToSnakeCase(tab.heading)).scrollIntoView({
                        behavior: 'smooth'
                      });
                    }
                    setActiveTab(index);
                  }}
                  className={clsx(
                    '[ text-white ][ w-full ][ mb-7 ][ cursor-pointer ]',
                    {
                      '[ border-l border-solid border-blue-30 ][ -ml-4 sm:-ml-6 lg:-ml-8 pl-4 sm:pl-6 lg:pl-8 ][ z-1000 ]': activeTab === index,
                    },
                  )}
                >
                  <div
                    className={clsx(
                      '[ text-20 font-medium ][  ][ mb-3 ]',
                      {
                        '[ text-gray-0/50 ]': activeTab !== index && bg === 'light',
                        '[ text-gray-0 ]': activeTab === index && bg === 'light',
                        '[ text-white/50 ]': activeTab !== index && bg !== 'light',
                        '[ text-white ]': activeTab === index && bg !== 'light',
                      },
                    )}
                  >
                    {tab.heading}
                  </div>
  
                  {activeTab === index && (
                    <>
                      <div
                        className={clsx(
                          '[ text-12 ][ mb-5 ]',
                          {
                            '[ text-gray-0 ]': bg === 'light',
                          },
                        )}
                      >
                        {tab.description}
                      </div>
  
                      <CommonButton
                        field={tab.cta_url}
                        color={bg === 'dark' ? 'white' : 'black'}
                        appearance="outline"
                        className="[ mb-6 lg:mb-0 ]"
                      >
                        {tab.cta_text}
                      </CommonButton>
                    </>
                  )}
  
                  <div
                    id={convertToSnakeCase(tab.heading)}
                    className={clsx(
                      '[ block lg:hidden ]',
                      {
                        '[ !hidden ]': activeTab !== index,
                        '[ text-gray-0 ]': bg === 'light',
                        '[ text-white ]': bg !== 'light',
                      },
                    )}
                  >
                    <PrismicNextImage field={items[activeTab].image} loading='lazy' />
  
                    {getContentToRender(undefined)}
                  </div>
                </div>
              ))}
            </div>
  
            {items.map((item, index) => (
              <div
                key={item.heading}
                className={clsx(
                  '[ hidden lg:block ]',
                  {
                    '[ !hidden ]': activeTab !== index,
                  },
                )}
              >
                <PrismicNextImage field={items[activeTab].image} loading='lazy' />
  
                {getContentToRender(index)}
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };